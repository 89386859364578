// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { dashboardApi } from "src/DAL/Login/Login";
// components
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
} from "../components/_dashboard/app";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import DashboardTable from "src/components/_dashboard/DashboardTable";
import RecentMembersTable from "src/components/_dashboard/RecentMembers";
import DashboardCalenders from "src/components/_dashboard/DashboardCalender";
import { defaultSettingApi } from "src/DAL/SiteSetting/siteSetting";
import Page from "src/components/Page";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function DashboardApp() {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [lessonCount, setLessonCount] = useState("");
  const [memberCount, setMemberCount] = useState("");
  const [programCount, setProgramCount] = useState("");
  const [programRecordingCount, setProgramRecordingCount] = useState("");
  const [recentLesson, setRecentLesson] = useState([]);
  const [generalInfo, setGeneralInfo] = useState();
  const [defaultTimeValue, setDefaultTimeValue] = useState();
  const [events, setEvents] = useState([]);
  const [inputs, setInputs] = useState({
    member_count: "",
    program_count: "",
    lesson_count: "",
    program_recording_count: "",
    recent_lesson: [],
    recent_member: [],
    events: [],
  });

  const getDashboard = async () => {
    setIsLoading(true);
    const result = await dashboardApi();

    if (result.code == 200) {
      setInputs(result);
      // setMemberCount(result.member_count);
      // setProgramCount(result.program_count);
      // setProgramRecordingCount(result.program_recording_count);
      // setRecentLesson(result.recent_lesson);
      // setRecentMember(result.recent_member);
      // setLessonCount(result.lesson_count);
      // setEvents(result.events);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const getSetting = async () => {
    const result = await defaultSettingApi();
    // console.log(result, "result");
    if (result.code == 200) {
      setGeneralInfo(result?.default_setting);
      setDefaultTimeValue(result);
    }
  };

  useEffect(() => {
    getSetting();
    getDashboard();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <Page title={generalInfo?.meta_title}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales total={inputs.program_recording_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers members={inputs.member_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders programs={inputs.program_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports lesson={inputs.lesson_count} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <RecentMembersTable recentMember={inputs.recent_member} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <DashboardTable recentLesson={inputs.recent_lesson} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <DashboardCalenders
              defaultTimeValue={defaultTimeValue}
              eventsList={inputs.events}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
